import { memo, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { visibleMainAtom } from "recoil/explore/visibleMain.atom";
import { monImagesSelector } from "recoil/images/images.selector";
import { landCheckAtom } from "recoil/landing/landing.atom";
import { stageIdxAtom } from "recoil/landing/stageIdx.atom";

import { monVisibleAtom } from "recoil/mon/monVisible.atom";
import styled from "styled-components";

const MonCharacter = () => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [mainVisible, setMainVisible] = useRecoilState(visibleMainAtom);
  const monVisible = useRecoilValue(monVisibleAtom);
  const monImages = useRecoilValue(monImagesSelector);
  const checkLand = useRecoilValue(landCheckAtom);
  const stageIdx = useRecoilValue(stageIdxAtom);

  const clickExploreBtn = () => {
    setMainVisible((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIdx((prevImage) => (prevImage + 1) % monImages.length);
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <MonContainerStyle>
      <MonBoxStyle $checkLand={checkLand}>
        <MonStyle $monVisible={monVisible}>
          {monImages.map((image, idx) => (
            <MonImageStyle
              key={idx}
              src={image}
              alt=""
              $idx={idx}
              $currentIdx={currentIdx}
              $stageIdx={stageIdx}
            />
          ))}
        </MonStyle>
        <ExploreBtnContainerStyle $mainVisible={mainVisible}>
          <ExploreBtnStyleStyle onClick={clickExploreBtn}>
            <span>explore</span>
          </ExploreBtnStyleStyle>
        </ExploreBtnContainerStyle>
      </MonBoxStyle>
    </MonContainerStyle>
  );
};

interface IStyled {
  $idx?: number;
  $currentIdx?: number;
  $mainVisible?: boolean;
  $checkLand?: boolean;
  $monVisible?: boolean;
}

const MonContainerStyle = styled.div`
  position: fixed;

  top: 0;
  left: 50%;
  width: 0 !important;
  height: 100vh;
  z-index: 10;
  user-select: none;
  @media (max-height: 820px) and (max-width: 1200px) and (min-width: 980px) {
    transform: scale(0.7, 0.7);
  }
`;

const MonBoxStyle = styled.div<IStyled>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: ${({ $checkLand }) => ($checkLand ? "600px" : "800px")};
  transform: translate(calc(-50%), -50%);
  @media (max-width: 979px) {
    height: ${({ $checkLand }) => ($checkLand ? "450px" : "650px")};
  }
`;

const MonStyle = styled.div<IStyled>`
  opacity: ${({ $monVisible }) => ($monVisible ? 0 : 1)};
  transition: opacity 2s;
  pointer-events: none;
  width: 500px;
  overflow: hidden;
`;

const MonImageStyle = styled.img<IStyled & { $stageIdx: number }>`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${({ $idx, $currentIdx }) => ($idx === $currentIdx ? 1 : 0)};
  transition: opacity 0.000001s linear, transform 1s linear;
  @media (max-width: 979px) {
    height: ${({ $stageIdx }) => ($stageIdx > 0 ? "400px" : "500px")};
    object-fit: contain;
    transform: ${({ $stageIdx }) =>
      $stageIdx > 80 && $stageIdx < 120
        ? "translateX(-270px)"
        : $stageIdx > 180 && $stageIdx < 220
        ? "translateX(270px)"
        : $stageIdx > 280 && $stageIdx < 320
        ? "translateX(-270px)"
        : $stageIdx > 480 && $stageIdx < 520
        ? "translateX(270px)"
        : $stageIdx > 580 && $stageIdx < 620
        ? "translateX(-270px)"
        : $stageIdx > 680 && $stageIdx < 720
        ? "translateX(270px)"
        : $stageIdx > 880 && $stageIdx < 920
        ? "translateX(-270px)"
        : ""};
    transition: transform 1s linear;
  }
`;

const ExploreBtnContainerStyle = styled.div<IStyled>`
  position: absolute;
  bottom: 3%;
  width: 100%;
  height: 64px;
  opacity: ${({ $mainVisible }) => (!$mainVisible ? 1 : 0)};
  visibility: ${({ $mainVisible }) => (!$mainVisible ? "visible" : "hidden")};
  transition: all 0.8s linear;
  text-align: center;
  cursor: pointer;

  @media (max-width: 979px) {
    margin-top: 15px;
    bottom: 23%;
  }
`;

const ExploreBtnStyleStyle = styled.button`
  margin: 0 auto;
  width: 200px;
  height: 64px;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 32px;
  background-color: rgba(255, 253, 245, 1);

  span {
    display: block;
    font-family: "DharmaGothicE";
  }
  @media (min-width: 980px) {
    overflow: hidden;
    transition: color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      position: relative;
      display: block;
      content: "";
      top: 100%;
      left: 0;
      width: 200px;
      height: 64px;
      border-radius: 32px;
      background-color: #222;
      transition: top 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover::before {
      top: 0;
    }
    &:hover {
      span {
        color: #fff;
      }
    }

    span {
      position: absolute;
      z-index: 1;
    }
  }
  @media (max-width: 979px) {
    width: 156px;
    height: 64px;
    font-size: 25px;
    line-height: 16vw;
    border-radius: 7.4666666667vw;
  }
`;

export default memo(MonCharacter);
