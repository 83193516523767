import { MENU_LIST, SNS_LIST, UTIL_LIST } from "contants/menuList";
import { memo } from "react";
import styled from "styled-components";
import ArrowImage from "assets/images/common/ico_link_blank.svg";
import { useSetRecoilState } from "recoil";
import { stageIdxAtom } from "recoil/landing/stageIdx.atom";
import { landCheckAtom } from "recoil/landing/landing.atom";
import { monVisibleAtom } from "recoil/mon/monVisible.atom";

interface MenuProps {
  menuOpen: boolean;
  handleMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({ menuOpen, handleMenu }) => {
  const setCurrentIdx = useSetRecoilState(stageIdxAtom);
  const setCheckLand = useSetRecoilState(landCheckAtom);
  const setMonVisible = useSetRecoilState(monVisibleAtom);

  return (
    <MenuContainerStyle $menuOpen={menuOpen}>
      <MenuContentStyle>
        <AsideContentStyle>
          <h3>DISCOVER</h3>
          <UtilContainerStyle>
            <ul>
              {UTIL_LIST.map((util) => (
                <li key={util.id}>
                  {!util.disabled ? (
                    <UtilLink
                      href={util.link}
                      target="'_blank"
                      rel="noreferrer"
                      $ArrowImage={ArrowImage}
                    >
                      {util.name}
                    </UtilLink>
                  ) : (
                    <span>{util.name}</span>
                  )}
                </li>
              ))}
            </ul>
          </UtilContainerStyle>
          <SnsContainerStyle>
            <ul>
              {SNS_LIST.map((sns) => (
                <li key={sns.id}>
                  <a href={sns.link} target="'_blank" rel="noreferrer">
                    <img src={sns.icon} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          </SnsContainerStyle>
        </AsideContentStyle>
        <PrimaryMenuStyle>
          <PrimaryMenuContainerStyle>
            <ul>
              {MENU_LIST.map((menu) => (
                <li
                  key={menu.id}
                  onClick={() => {
                    setCheckLand(true);
                    setMonVisible(false);
                    if (menu.id === 1) setCurrentIdx(100);
                    if (menu.id === 2) setCurrentIdx(200);
                    if (menu.id === 3) setCurrentIdx(300);
                    if (menu.id === 4) setCurrentIdx(500);
                    if (menu.id === 5) setCurrentIdx(600);
                    if (menu.id === 6) setCurrentIdx(700);
                    if (menu.id === 7) setCurrentIdx(900);
                    handleMenu();
                  }}
                >
                  <div>
                    <span>0{menu.id}</span>
                    <span>{menu.title}</span>
                  </div>
                </li>
              ))}
            </ul>
          </PrimaryMenuContainerStyle>
        </PrimaryMenuStyle>
      </MenuContentStyle>
    </MenuContainerStyle>
  );
};

interface IStyled {
  $menuOpen?: boolean;
  $ArrowImage?: string;
}

const MenuContainerStyle = styled.div<IStyled>`
  position: fixed;
  top: 0;
  right: 0;
  width: ${({ $menuOpen }) => (!$menuOpen ? "1%" : "100%")};
  height: ${({ $menuOpen }) => (!$menuOpen ? "0" : "100%")};
  background-color: #222;
  border-bottom-left-radius: ${({ $menuOpen }) => (!$menuOpen ? "100vh" : "0")};
  overflow: hidden;
  z-index: 1000;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media (max-width: 979px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      padding: 5.8666666667vw 0;
      width: 95%;
      height: 3.2vw;
      z-index: 2;
      backdrop-filter: blur(8px);
    }
  }
`;

const MenuContentStyle = styled.div`
  display: flex;
  position: absolute;
  padding: 0 0 0 80px;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: 979px) {
    flex-direction: column;
    padding-left: 5.3333333333vw;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const AsideContentStyle = styled.div`
  padding-top: 230px;
  flex-basis: 560px;
  h3 {
    font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 24px;
    line-height: 1.6;
    font-weight: bold;

    color: #fffdf5;
  }
  @media (max-width: 979px) {
    padding-top: 21.3333333333vw;
    padding-bottom: 21.3333333333vw;
    order: 2;
    h3 {
      font-size: 5.3333333333vw;
    }
  }
`;

const UtilContainerStyle = styled.div`
  margin-top: 48px;
  font-size: 20px;
  ul {
    li {
      margin-top: 12px;
      line-height: 1.6;
      span {
        color: rgba(255, 253, 245, 0.5);
        display: inline-block;
        font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue",
          "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
          sans-serif;
      }
    }
  }
  @media (max-width: 979px) {
    margin-top: 8.5333333333vw;
    font-size: 4.8vw;
    ul {
      li {
        margin-top: 3.2vw;
        span {
          font-size: 3.7333333333vw;
        }
      }
    }
  }
`;

const UtilLink = styled.a<IStyled>`
  color: #fffdf5;
  font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;

  @media (min-width: 980px) {
    display: inline-block;
    position: relative;
    height: 1.5em;
    &::after {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left: 100%;
      width: 20px;
      height: 20px;
      margin-left: 0;
      opacity: 0;
      background: ${({ $ArrowImage }) =>
        `url(${$ArrowImage}) no-repeat center center`};
      background-size: 100% 100%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover::after {
      top: 0;
      margin-left: 8px;
      opacity: 1;
    }
  }
`;

const SnsContainerStyle = styled.div`
  margin-top: 80px;
  ul {
    display: flex;
    li {
      margin-right: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 979px) {
    margin-top: 21.3333333333vw;
    ul {
      li {
        margin-right: 5.3333333333vw;
        img {
          width: 6.4vw;
          height: 6.4vw;
        }
      }
    }
  }
`;

const PrimaryMenuStyle = styled.div`
  width: 100%;
  @media (max-width: 979px) {
    order: 1;
  }
`;

const PrimaryMenuContainerStyle = styled.nav`
  padding: 200px 0;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ul {
    display: inline-block;
    :nth-child(1) {
      margin-top: 0;
    }
    li {
      margin-top: 20px;
      cursor: pointer;
      display: block;
      opacity: 1;
      :nth-child(1) {
        font-size: 20px;
        color: rgba(255, 253, 245, 0.5);
        font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue",
          "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
          sans-serif;
        margin-right: 2.5px;
      }
      :nth-child(2) {
        display: inline-block;
        margin-left: 40px;
        height: 1em;
        line-height: 1em;
        font-family: "DharmaGothicE";
        font-size: 160px;
        font-weight: 500;
        color: #fffdf5;
        text-transform: uppercase;
      }
    }
  }

  @media (min-width: 980px) {
    ul:hover {
      transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.1;
      li {
        div:not(:hover) {
          transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 0.1;
        }
      }
    }

    ul:hover {
      transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1;

      li {
        div:hover {
          transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 979px) {
    padding: 0;
    height: auto;
    overflow: visible;
    ul {
      padding-top: 25.6vw;
      padding-bottom: 0;
      li {
        margin-top: 3.2vw;

        :nth-child(1) {
          font-size: 3.7333333333vw;
        }
        :nth-child(2) {
          margin-left: 3.2vw;
          font-size: 17.0666666667vw;
        }
      }
    }
  }
`;

export default memo(Menu);
