export const UTIL_LIST = [
  {
    id: 1,
    name: "White paper",
    link: "https://another-world-4.gitbook.io/another-world-whitepaper/introduction/history",
    disabled: false,
  },
  {
    id: 2,
    name: "AW Swap",
    link: "https://app.anotherworld.game/",
    disabled: false,
  },
  {
    id: 3,
    name: "Mapper",
    link: "",
    disabled: true,
  },
  {
    id: 4,
    name: "Iceberg",
    link: "",
    disabled: true,
  },
];

export const SNS_LIST = [
  {
    id: 1,
    link: "https://kei.anotherworld.game/",
    icon: "/static/images/common/ico_sns_kei@2x.png",
  },
  {
    id: 2,
    link: "https://twitter.com/anotherworldgm",
    icon: "/static/images/common/ico_sns_twitter.svg",
  },
  {
    id: 3,
    link: "https://www.youtube.com/channel/UCGWoNo1ZDmxQdiTcNBNd74Q",
    icon: "/static/images/common/ico_sns_youtube.svg",
  },
  {
    id: 4,
    link: "https://discord.com/invite/anotherworldgm",
    icon: "/static/images/common/ico_sns_discord.svg",
  },
  {
    id: 5,
    link: "https://t.me/anotherworldgm",
    icon: "/static/images/common/ico_sns_telegram.svg",
  },
  {
    id: 6,
    link: "https://medium.com/anotherworld",
    icon: "/static/images/common/ico_sns_medium.svg",
  },
];

export const MENU_LIST = [
  {
    id: 1,
    title: "Land",
  },
  {
    id: 2,
    title: "avatar",
  },
  {
    id: 3,
    title: "another tool",
  },
  {
    id: 4,
    title: "place",
  },
  {
    id: 5,
    title: "tokenomics",
  },
  {
    id: 6,
    title: "game",
  },
  {
    id: 7,
    title: "vision",
  },
];
