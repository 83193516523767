import {
  ExploreMain,
  Header,
  MainLoading,
  MonCharacter,
  Stage,
} from "components";

import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { landCheckAtom } from "recoil/landing/landing.atom";
import { loadingAtom } from "recoil/loading.atom";
import styled from "styled-components";

function App() {
  const checkLand = useRecoilValue(landCheckAtom);
  const loading = useRecoilValue(loadingAtom);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        setVisible(true);
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <MainLoading />
      <Container $visible={visible}>
        <Header />
        <main>
          <MonCharacter />
          {!checkLand && <ExploreMain />}
          <Stage />
        </main>
      </Container>
    </>
  );
}

const Container = styled.div<{ $visible: boolean }>`
  opacity: ${({ $visible }) => (!$visible ? 0 : 1)};
  transition: opacity 3s ease-in-out;
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: 979px) {
    &::after {
      position: fixed;
      content: "";
      padding: 5.8666666667vw 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 3.2vw;
      backdrop-filter: blur(8px);
      z-index: 200;
    }
  }
`;

export default App;
