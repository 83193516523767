import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { visibleMainAtom } from "recoil/explore/visibleMain.atom";
import { landCheckAtom } from "recoil/landing/landing.atom";
import { monVisibleAtom } from "recoil/mon/monVisible.atom";
import styled from "styled-components";

const StageEnd: React.FC<{
  currentIdx: number;
  setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
}> = ({ currentIdx, setCurrentIdx }) => {
  const [toggleState, setToggleState] = useState(false);
  const setLandCheck = useSetRecoilState(landCheckAtom);
  const setVisibleMain = useSetRecoilState(visibleMainAtom);
  const setMonVisible = useSetRecoilState(monVisibleAtom);

  const clickAgain = () => {
    setToggleState(true);
    setTimeout(async () => {
      await setMonVisible(false);
      setCurrentIdx(0);
      setVisibleMain(false);
      setLandCheck(false);
      setToggleState(false);
    }, 2000);
  };
  return (
    <>
      <BackgroundColorStyled $toggleState={toggleState} />
      <ContainerStyle $currentIdx={currentIdx} className="end-container">
        <strong>
          If you enjoyed
          <br className="mo" /> this ride,
          <br className="pc" />
          <br className="mo" />
          wait to see what’s
          <br className="mo" /> about to come
        </strong>
        {currentIdx === 1016 && (
          <BtnContainerStyle>
            <button onClick={clickAgain}>
              <span>explore again</span>
            </button>
          </BtnContainerStyle>
        )}

        <FooterContainerStyle>
          <SNSContainerStyle>
            <ul>
              <li>
                <a
                  href="https://kei.anotherworld.game/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/images/common/ico_sns_kei@2x.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/anotherworldgm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/images/common/ico_sns_twitter.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCGWoNo1ZDmxQdiTcNBNd74Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/images/common/ico_sns_youtube.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/anotherworldgm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/images/common/ico_sns_discord.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/anotherworldgm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/static/images/common/ico_sns_telegram.svg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/anotherworld"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/static/images/common/ico_sns_medium.svg" alt="" />
                </a>
              </li>
            </ul>
          </SNSContainerStyle>
          <CopyrightStyle className="copyright">© anotherworld.</CopyrightStyle>
        </FooterContainerStyle>
      </ContainerStyle>
    </>
  );
};

interface IStyled {
  $currentIdx: number;
}

const BackgroundColorStyled = styled.div<{ $toggleState: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ $toggleState }) => ($toggleState ? "#fff" : "none")};
  transition: all 3s;
`;

const ContainerStyle = styled.div<IStyled>`
  position: absolute;
  flex-direction: column;
  top: 86%;
  left: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-origin: center center;
  text-transform: uppercase;
  opacity: ${({ $currentIdx }) => ($currentIdx === 1016 ? 1 : 0)};
  transform: ${({ $currentIdx }) =>
    $currentIdx === 1016 ? "translate(-50%, -50%)" : "translate(-50%, -60%)"};
  transition: all 1s;

  z-index: 13;
  strong {
    display: block;
    font-size: 120px;
    line-height: 1em;
    color: #fff;
    font-family: "DharmaGothicE";
    .mo {
      display: none;
    }
  }

  @media (max-width: 979px) {
    top: 80%;
    strong {
      font-size: 17.0666666667vw;
      .pc {
        display: none;
      }
      .mo {
        display: inline;
      }
    }
  }
`;

const BtnContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  button {
    width: 200px;
    height: 2em;
    line-height: 2.1em;
    color: #222;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 32px;
    background-color: rgba(255, 253, 245, 0.5);
    border: none;
    span {
      padding-top: 5px;
      font-family: "DharmaGothicE";
    }
  }

  @media (min-width: 980px) {
    button {
      overflow: hidden;
      transition: color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      &::before {
        position: relative;
        display: block;
        content: "";
        top: 100%;
        left: 0;
        width: 200px;
        height: 64px;
        border-radius: 32px;
        background-color: #222;
        transition: top 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover::before {
        top: 0;
      }

      :hover {
        color: #fff;
      }

      span {
        position: absolute;
        z-index: 1;
      }
    }
  }

  @media (max-width: 979px) {
    margin-top: 10.6666666667vw;
    button {
      width: 40vw;
      height: 14.9333333333vw;
      line-height: 16vw;
      font-size: 6.4vw;
      border-radius: 7.4666666667vw;
    }
  }
`;

const FooterContainerStyle = styled.footer`
  position: absolute;
  bottom: 530px;
  left: 0;
  width: 100%;
  @media (max-width: 979px) {
    bottom: 42.3333333333vh;
  }
`;

const SNSContainerStyle = styled.div`
  position: absolute;
  left: 80px;
  ul {
    display: flex;
    li {
      float: left;
      margin-left: 20px;
      a {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (max-width: 979px) {
    top: 10.9333333333vw;
    left: 5.3333333333vw;
    ul {
      li {
        float: left;
        margin-left: 5.3333333333vw;
        a {
          img {
            width: 5.8666666667vw;
            height: 5.8666666667vw;
          }
        }
      }
    }
  }
`;

const CopyrightStyle = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  color: #f8f8f8;
  font-weight: 300;
  font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  @media (max-width: 979px) {
    font-size: 3.7333333333vw;
  }
`;

export default StageEnd;
