import { memo, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  fogImagesSelector,
  stage2ImagesSelector,
  stageImagesSelector,
} from "recoil/images/images.selector";
import { landCheckAtom } from "recoil/landing/landing.atom";
import styled from "styled-components";
import StageEnd from "./StageEnd";
import { monVisibleAtom } from "recoil/mon/monVisible.atom";
import { stageIdxAtom } from "recoil/landing/stageIdx.atom";
import { imageCountAtom, loadingAtom } from "recoil/loading.atom";
import { isMobile } from "react-device-detect";

const Stage = () => {
  const [stageImages, setStageImages] = useState<any[]>([]);
  const [fogImages, setFogImages] = useState<any[]>([]);
  const [fogIdx, setFogIdx] = useState(0);
  const [startCanvas, setStartCanvas] = useState(true);

  const [currentIdx, setCurrentIdx] = useRecoilState(stageIdxAtom);
  const [checkLand, setCheckLand] = useRecoilState(landCheckAtom);

  const stageImageArr = useRecoilValue(stageImagesSelector);
  const stage2ImageArr = useRecoilValue(stage2ImagesSelector);
  const fogImageArr = useRecoilValue(fogImagesSelector);

  const setMonVisible = useSetRecoilState(monVisibleAtom);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const setImageCount = useSetRecoilState(imageCountAtom);

  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const fogIntervalRef = useRef<NodeJS.Timer | null>(null);
  const driveRef = useRef<boolean>(false);
  const fogShowRef = useRef<boolean>(false);
  const touchPositionRef = useRef<number>(0);
  const canvasRef = useRef<any>(null);
  const fogCanvasRef = useRef<any>(null);

  const [observerRef, observerInView] = useInView();

  useEffect(() => {
    if (observerInView && !checkLand) {
      document.body.classList.add("hidden");
      setCheckLand(true);
    }
    return () => {
      document.body.classList.remove("hidden");
    };
  }, [observerInView]);

  const handleMotionStage = (arriveStage: number, reverse: boolean) => {
    driveRef.current = true;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const interval = setInterval(() => {
      setCurrentIdx((prev) => {
        if (prev < arriveStage && !reverse) {
          return prev + 1;
        } else if (prev >= arriveStage && reverse) {
          return prev - 1;
        } else {
          driveRef.current = false;
          clearInterval(interval);
          return prev;
        }
      });
    }, 50);

    intervalRef.current = interval;

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  };

  const handleScroll = (deltaY: number) => {
    if (driveRef.current) return;
    if (deltaY > 0) {
      if (currentIdx < 100) {
        handleMotionStage(100, false);
      }
      if (currentIdx >= 100 && currentIdx < 200) {
        handleMotionStage(200, false);
      }
      if (currentIdx >= 200 && currentIdx < 300) {
        handleMotionStage(300, false);
      }
      if (currentIdx >= 300 && currentIdx < 400) {
        handleMotionStage(400, false);
      }
      if (currentIdx >= 400 && currentIdx < 500) {
        handleMotionStage(500, false);
      }
      if (currentIdx >= 500 && currentIdx < 600) {
        handleMotionStage(600, false);
      }
      if (currentIdx >= 600 && currentIdx < 700) {
        handleMotionStage(700, false);
      }
      if (currentIdx >= 700 && currentIdx < 800) {
        handleMotionStage(800, false);
      }
      if (currentIdx >= 800 && currentIdx < 900) {
        handleMotionStage(900, false);
      }
      if (currentIdx >= 900 && currentIdx < 1016) {
        handleMotionStage(1016, false);
      }
    } else if (deltaY < 0) {
      if (currentIdx <= 100) {
        handleMotionStage(2, true);
      }
      if (currentIdx > 100 && currentIdx <= 200) {
        handleMotionStage(101, true);
      }
      if (currentIdx > 200 && currentIdx <= 300) {
        handleMotionStage(201, true);
      }
      if (currentIdx > 300 && currentIdx <= 400) {
        handleMotionStage(301, true);
      }
      if (currentIdx > 400 && currentIdx <= 500) {
        handleMotionStage(401, true);
      }
      if (currentIdx > 500 && currentIdx <= 600) {
        handleMotionStage(501, true);
      }
      if (currentIdx > 600 && currentIdx <= 700) {
        handleMotionStage(601, true);
      }
      if (currentIdx > 700 && currentIdx <= 800) {
        handleMotionStage(701, true);
      }
      if (currentIdx > 800 && currentIdx <= 900) {
        handleMotionStage(801, true);
      }
    }
  };

  const handleWheel = (e: WheelEvent) => {
    handleScroll(e.deltaY);
  };

  const handleTouchStart = (e: TouchEvent) => {
    touchPositionRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const deltaY = touchPositionRef.current - currentY;
    handleScroll(deltaY);
  };

  useEffect(() => {
    if (currentIdx === 960) {
      setMonVisible(true);
    }

    if (checkLand) {
      if (!isMobile) {
        window.addEventListener("wheel", handleWheel);
        return () => {
          window.removeEventListener("wheel", handleWheel);
        };
      } else {
        window.addEventListener("touchstart", handleTouchStart);
        window.addEventListener("touchmove", handleTouchMove);
        return () => {
          window.removeEventListener("touchstart", handleTouchStart);
          window.removeEventListener("touchmove", handleTouchMove);
        };
      }
    }
  }, [checkLand, currentIdx]);

  const loadFogImages = async () => {
    const loadImage = await Promise.all(
      fogImageArr.map(
        (image) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = image;
            img.onload = () => resolve(img);
          })
      )
    );
    setFogImages([...loadImage]);
  };

  useEffect(() => {
    loadFogImages();
  }, []);

  useEffect(() => {
    const canvas = fogCanvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!isLoading && fogIdx < 70) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(fogImages[fogIdx], 0, 0, canvas.width, canvas.height);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [fogIdx]);

  const handleFogScroll = () => {
    fogShowRef.current = true;
    if (fogIntervalRef.current) {
      clearInterval(fogIntervalRef.current);
    }
    const interval = setInterval(() => {
      setFogIdx((prev) => {
        if (prev < fogImageArr.length) {
          return prev + 1;
        } else {
          clearInterval(interval);
          fogShowRef.current = false;
          return prev;
        }
      });
      fogIntervalRef.current = interval;
    }, 55);
    return () => {
      if (fogIntervalRef.current) {
        clearInterval(fogIntervalRef.current);
      }
    };
  };

  const moveFogMotion = (deltaY: number) => {
    if (fogShowRef.current) return;
    setFogIdx(0);

    if (deltaY > 0 && currentIdx === 301) {
      handleFogScroll();
    }
    if (deltaY > 0 && currentIdx === 701) {
      handleFogScroll();
    }
    if (deltaY < 0 && currentIdx === 399) {
      handleFogScroll();
    }
    if (deltaY < 0 && currentIdx === 799) {
      handleFogScroll();
    }
  };

  const handleFogWheel = (e: WheelEvent) => {
    moveFogMotion(e.deltaY);
  };

  const handleFogTouchMove = (e: TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const deltaY = touchPositionRef.current - currentY;
    moveFogMotion(deltaY);
  };

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("wheel", handleFogWheel);
      return () => {
        window.removeEventListener("wheel", handleFogWheel);
      };
    } else {
      window.addEventListener("touchstart", handleTouchStart);
      window.addEventListener("touchmove", handleFogTouchMove);
      return () => {
        window.removeEventListener("touchstart", handleTouchStart);
        window.removeEventListener("touchmove", handleFogTouchMove);
      };
    }
  }, [currentIdx, fogIdx]);

  const loadImages = async () => {
    setStartCanvas(false);
    const loadImages = await Promise.all(
      stageImageArr.map(
        (image) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              resolve(img);
              setImageCount((prev) => prev + 1);
            };
          })
      )
    );

    const interval = setInterval(() => {
      setIsLoading(false);
    }, 2000);

    const loadSecImages = await Promise.all(
      stage2ImageArr.map(
        (image) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = image;
            img.onload = () => resolve(img);
          })
      )
    );

    setStageImages([...loadImages, ...loadSecImages]);
    return () => {
      clearInterval(interval);
    };
  };

  useEffect(() => {
    loadImages();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!startCanvas) {
      ctx.drawImage(stageImages[currentIdx], 0, 0, canvas.width, canvas.height);
    }
  }, [currentIdx, stageImages]);

  return (
    <>
      <StageContainerStyle $backImage={stageImageArr[0]} $checkLand={checkLand}>
        <StageImageStyle ref={canvasRef} />
        <StageWrapperStyle>
          <TextContainerStyle>
            <Chapter1Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 01 : LAND</span>
                </ChapterTitleStyle>
                <strong>Mysterious Land</strong>
                <span>
                  You might wonder what we are doing here...{" "}
                  <br className="pc" /> but relax, sit tight! This vast nothing
                  before you is <br className="pc" /> called Zin— a faraway land{" "}
                  from Another City.
                  <br />
                  In Another World exist many different <br className="pc" />{" "}
                  geographical landscapes where infinite possibilities{" "}
                  <br className="pc" /> remain undiscovered.
                  <br />
                  We are about to begin tales of great mystery.
                </span>
              </TextInnerBoxStyle>
            </Chapter1Style>
            <Chapter2Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 02 : AVATAR</span>
                </ChapterTitleStyle>
                <strong>
                  What would you like
                  <br className="mo" /> to live as?
                </strong>
                <span className="text">
                  You can be a human or an animal. You can <br className="mo" />{" "}
                  even be a <br className="pc" /> human in the form of an
                  animal. <br className="mo" /> In Another World,{" "}
                  <br className="pc" /> everyone lives <br className="mo" />{" "}
                  harmoniously in different forms. <br className="pc" />
                  Feel free to choose a shape of life you desire to take on!
                </span>
              </TextInnerBoxStyle>
            </Chapter2Style>
            <Chapter3Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 03 : ANOTHER TOOL</span>
                </ChapterTitleStyle>
                <strong>
                  Creation is <br className="mo" /> privilege!
                </strong>
                <span>
                  We will set into the world for creators who make our space
                  even more boundless.
                  <br />
                  In Another World, programmers and creators without coding
                  knowledge alike can build anything.
                </span>
              </TextInnerBoxStyle>
            </Chapter3Style>
            <Chapter4Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 04 : PLACE</span>
                </ChapterTitleStyle>
                <strong>
                  A space that <br className="mo" /> completes us
                </strong>
                <span className="text">
                  We provide everything that a Web3 <br className="pc" />{" "}
                  business requires so that you can have <br className="pc" /> a
                  nice life in Another World.
                  <br />
                  Moreover, we help create valuable projects.
                </span>
              </TextInnerBoxStyle>
            </Chapter4Style>
            <Chapter5Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 05 : TOKENOMICS</span>
                </ChapterTitleStyle>
                <strong>AWM TOKEN</strong>
                <span>
                  AWM, which is at the core of every balance,{" "}
                  <br className="pc" /> is the currency of Another World. <br />
                  The Tokenomics that composes it reflects our{" "}
                  <br className="pc" /> values and heart for our community
                  members.
                </span>
              </TextInnerBoxStyle>
            </Chapter5Style>
            <Chapter6Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 06 : GAME</span>
                </ChapterTitleStyle>
                <strong>Native Game-Fi</strong>
                <span className="text">
                  Games have the power to show the world <br className="pc" />{" "}
                  we live in and its problems from multiple{" "}
                  <br className="pc" /> different perspectives.
                  <br className="mo" />
                  To make world a <br className="pc" /> better place, Another
                  World develops <br className="pc" /> games that are diverse,
                  experimental, and <br className="pc" /> ultimately
                  entertaining.
                </span>
              </TextInnerBoxStyle>
            </Chapter6Style>
            <Chapter7Style $currentIdx={currentIdx}>
              <TextInnerBoxStyle>
                <ChapterTitleStyle>
                  <span>CHAPTERS 07 : VISION</span>
                </ChapterTitleStyle>
                <strong>A better world</strong>
                <span>
                  Our Web3.0 technology is a tool for people.
                  <br />
                  Through our technology, people constantly{" "}
                  <br className="pc" /> push their limits by creating, owning,{" "}
                  <br className="pc" /> and sharing ideas that will change the
                  world.
                  <br />
                  <span>
                    You can learn more about our journey in detail{" "}
                    <a
                      href="https://another-world-4.gitbook.io/another-world-whitepaper/introduction/history"
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        textTransform: "lowercase",
                        textDecoration: "underLine",
                      }}
                    >
                      here
                    </a>
                  </span>
                  <br />
                  Check out the near—and the ultimate— <br className="pc" />{" "}
                  future we are envisioning.
                </span>
              </TextInnerBoxStyle>
            </Chapter7Style>
          </TextContainerStyle>
        </StageWrapperStyle>
        <div
          ref={observerRef}
          style={{ position: "absolute", bottom: "1px" }}
        />
        <StageEnd currentIdx={currentIdx} setCurrentIdx={setCurrentIdx} />
      </StageContainerStyle>

      {/* {fogImageArr.map((image, idx) => (
        <FogContainerStyle key={idx}>
          <FogImageStyle src={image} $fogIdx={fogIdx} $idx={idx} alt="" />
        </FogContainerStyle>
      ))} */}
      <FogContainerStyle>
        <FogImageStyle ref={fogCanvasRef} />
      </FogContainerStyle>
    </>
  );
};

interface IStyled {
  $currentIdx: number;
  $idx?: number;
}

const StageContainerStyle = styled.div<{
  $backImage: string;
  $checkLand: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${({ $checkLand }) => ($checkLand ? "100vh" : "110vh")};
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: ${({ $backImage }) => `url(${$backImage})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const StageWrapperStyle = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  z-index: 100;
  @media (max-height: 820px) and (max-width: 1200px) and (min-width: 980px) {
    transform: scale(0.7, 0.7);
  }
`;

const StageImageStyle = styled.canvas`
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextContainerStyle = styled.div`
  position: absolute;
  bottom: 240px;
  left: 275px;
  width: 100%;
  height: 100%;

  @media (max-width: 979px) {
    div {
      left: 61%;
      right: 4%;
    }
  }
`;

const CommonChapterStyle = styled.div`
  position: absolute;
  top: 48%;
  width: auto;
  transition: all 1.2s ease-in-out, filter 1s ease-in-out;
  @media (max-width: 979px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Chapter1Style = styled(CommonChapterStyle)<IStyled>`
  left: 62%;
  right: 2%;
  transform-origin: left 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 110 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 110
      ? "scale(3,3) translateX(300px)"
      : $currentIdx < 80
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 120 && $currentIdx >= 80 ? 1 : 0};

  @media (max-width: 979px) {
    text-align: left;
    transform: ${({ $currentIdx }) =>
      $currentIdx > 110 && "translateX(800px) scale(2, 2)"};
  }
  div > span {
    width: 310px;
  }
`;

const Chapter2Style = styled(CommonChapterStyle)<IStyled>`
  right: 570px;
  text-align: right;
  transform-origin: right 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 215 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 210
      ? "scale(3,3) translateX(-300px)"
      : $currentIdx < 180
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 220 && $currentIdx >= 180 ? 1 : 0};
  @media (max-width: 979px) {
    transform: ${({ $currentIdx }) =>
      $currentIdx > 220
        ? "translateX(-800px) scale(3, 3)"
        : $currentIdx < 180
        ? "translate(-800px) scale(0.2,0.2)"
        : "translate(-150px) scale(1, 1)"};

    div > .text {
      width: 310px;
      transform: translateX(110px);
    }
  }
`;
const Chapter3Style = styled(CommonChapterStyle)<IStyled>`
  left: 62%;
  right: 2%;
  transform-origin: left 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 310 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 310
      ? "scale(3,3) translateX(300px)"
      : $currentIdx < 280
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 320 && $currentIdx >= 280 ? 1 : 0};

  @media (max-width: 979px) {
    transform: ${({ $currentIdx }) =>
      $currentIdx > 310 && "translateX(800px) scale(2, 2)"};
    div > span {
      width: 310px;
    }
  }
`;
const Chapter4Style = styled(CommonChapterStyle)<IStyled>`
  right: 570px;
  text-align: right;
  transform-origin: right 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 515 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 510
      ? "scale(3,3) translateX(-300px)"
      : $currentIdx < 480
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 520 && $currentIdx >= 480 ? 1 : 0};

  @media (max-width: 979px) {
    transform: ${({ $currentIdx }) =>
      $currentIdx > 520
        ? "translateX(-800px) scale(3, 3)"
        : $currentIdx < 480
        ? "translate(-800px) scale(0.2,0.2)"
        : "translate(-150px) scale(1, 1)"};
    div > .text {
      width: 310px;
      transform: translateX(110px);
    }
  }
`;
const Chapter5Style = styled(CommonChapterStyle)<IStyled>`
  left: 62%;
  right: 2%;
  transform-origin: right 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 610 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 610
      ? "scale(3,3) translateX(300px)"
      : $currentIdx < 580
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 620 && $currentIdx >= 580 ? 1 : 0};
  @media (max-width: 979px) {
    margin-top: 40px;
    transform: ${({ $currentIdx }) =>
      $currentIdx > 610 && "translateX(800px) scale(2, 2)"};
    div > span {
      width: 310px;
    }
  }
`;
const Chapter6Style = styled(CommonChapterStyle)<IStyled>`
  right: 570px;
  text-align: right;
  transform-origin: right 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 715 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 710
      ? "scale(3,3) translateX(-300px)"
      : $currentIdx < 680
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 720 && $currentIdx >= 680 ? 1 : 0};
  @media (max-width: 979px) {
    transform: ${({ $currentIdx }) =>
      $currentIdx > 720
        ? "translateX(-800px) scale(3, 3)"
        : $currentIdx < 680
        ? "translate(-800px) scale(0.2,0.2)"
        : "translate(-150px) scale(1, 1)"};
    div > .text {
      width: 310px;
      transform: translateX(110px);
    }
  }
`;
const Chapter7Style = styled(CommonChapterStyle)<IStyled>`
  left: 62%;
  right: 2%;
  transform-origin: left 20%;
  filter: ${({ $currentIdx }) => ($currentIdx > 910 ? "blur(5px)" : "none")};
  transform: ${({ $currentIdx }) =>
    $currentIdx > 910
      ? "scale(3,3) translateX(300px)"
      : $currentIdx < 880
      ? "scale(0.2,0.2)"
      : "scale(1,1)"};
  opacity: ${({ $currentIdx }) =>
    $currentIdx <= 920 && $currentIdx >= 880 ? 1 : 0};

  @media (max-width: 979px) {
    transform: ${({ $currentIdx }) =>
      $currentIdx > 910 && "translateX(800px) scale(2, 2)"};
    div > span {
      width: 310px;
    }
  }
`;

const TextInnerBoxStyle = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 420px;
  strong {
    display: block;
    margin-top: 8px;
    line-height: 1;
    font-size: 80px;
    font-family: "DharmaGothicE";
    font-weight: 400;
    text-transform: uppercase;
    .mo {
      display: none;
    }
    @media (max-width: 979px) {
      .mo {
        display: inline;
      }
    }
  }
  span {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.6;
    letter-spacing: 0.05em;
    font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
    .mo {
      display: none;
    }
    .pc {
      display: inline;
    }
    strong {
      a {
        display: inline-block;
        padding: 6px 0;
        font-weight: 500;
        font-style: italic;
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 979px) {
    strong {
      margin-top: 2.1333333333vw;
      font-size: 13.3333333333vw;
    }
    > span {
      margin-top: 5.3333333333vw;
      font-size: 3.7333333333vw;
      font-weight: 300;
      letter-spacing: 0.01em;
      line-height: 1.8;
      .mo {
        display: inline;
      }
      .pc {
        display: none;
      }
    }
  }
`;

const ChapterTitleStyle = styled.div`
  span {
    width: auto;
    display: inline-block;
    position: relative;
    padding-left: 24px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    text-transform: uppercase;
    color: #222;
    font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
    letter-spacing: 0;
    &::before {
      display: block;
      position: absolute;
      content: "";
      top: 8px;
      left: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #222;
    }
  }
  @media (max-width: 979px) {
    span {
      padding-left: 5.3333333333vw;
      font-size: 3.7333333333vw;
      &::before {
        top: 0.6333333333vw;
        width: 3.2vw;
        height: 3.2vw;
      }
    }
  }
`;

const FogContainerStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  pointer-events: none;
`;

const FogImageStyle = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default memo(Stage);
