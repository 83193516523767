import { memo } from "react";
import { useRecoilValue } from "recoil";
import { imageCountAtom, loadingAtom } from "recoil/loading.atom";
import styled from "styled-components";

const MainLoading = () => {
  const loading = useRecoilValue(loadingAtom);
  const imageCount = useRecoilValue(imageCountAtom);

  return (
    <LoadingContainerStyle $loading={loading}>
      <LoadingAnimationBoxStyle>
        <DotStyle $activeIdx={0} />
        <DotStyle $activeIdx={1} />
        <DotStyle $activeIdx={2} />
        <DotStyle $activeIdx={3} />
        <DotStyle $activeIdx={4} />
        <DotStyle $activeIdx={5} />
        <DotStyle $activeIdx={6} />
        <DotStyle $activeIdx={7} />
        <DotStyle $activeIdx={8} />
        <DotStyle $activeIdx={9} />
        <DotStyle $activeIdx={10} />
        <DotStyle $activeIdx={11} />
        <DotStyle $activeIdx={12} />
        <DotStyle $activeIdx={13} />
        <DotStyle $activeIdx={14} />
        <DotStyle $activeIdx={15} />
        <DotStyle $activeIdx={16} />
        <DotStyle $activeIdx={17} />
        <DotStyle $activeIdx={18} />
        <DotStyle $activeIdx={19} />
      </LoadingAnimationBoxStyle>
      <CountStyle>{Math.floor((imageCount / 508) * 100)}%</CountStyle>
    </LoadingContainerStyle>
  );
};

const LoadingContainerStyle = styled.div<{ $loading: boolean }>`
  opacity: ${({ $loading }) => ($loading ? 1 : 0)};
  visibility: ${({ $loading }) => ($loading ? "visible" : "hidden")};
  transition: all 1s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  box-sizing: border-box;
`;

const LoadingAnimationBoxStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -180px;
  margin-top: -180px;
  width: 360px;
  height: 360px;
  aspect-ratio: 1;
  position: relative;

  @media (max-width: 979px) {
    width: 69.3333333333vw;
    height: 69.3333333333vw;
    margin-left: -34.6666666667vw;
    margin-top: -34.6666666667vw;
    div:before {
      .loading-ani > *::before {
        margin-left: -vw(4px);
        width: 2.1333333333vw;
        height: 2.1333333333vw;
      }
    }
  }
`;

const DotStyle = styled.div<{ $activeIdx: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: ${({ $activeIdx }) => `rotate(calc(360deg / 20 * ${$activeIdx}))`};
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    border-radius: 50%;
    background-color: #222;
    animation: scaleTo1 3s ease-in-out
      ${({ $activeIdx }) => `calc(3s / 20 * ${$activeIdx})`} infinite;
  }

  @keyframes scaleTo1 {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(2);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const CountStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 280px;
  height: 100px;
  margin-top: -50px;
  margin-left: -140px;
  line-height: 100px;
  text-align: center;
  font-size: 94px;
  font-family: "DharmaGothicE";
  z-index: 1;
  @media (max-width: 979px) {
    width: 53.3333333333vw;
    height: 18.6666666667vw;
    line-height: 20.2666666667vw;
    margin-top: -9.3333333333vw;
    margin-left: -26.6666666667vw;
    font-size: 17.0666666667vw;
  }
`;

export default memo(MainLoading);
