import { atom } from "recoil";

export const loadingAtom = atom({
  key: "loadingAtom",
  default: true,
});

export const imageCountAtom = atom({
  key: "imageCountAtom",
  default: 0,
});
