import { memo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { visibleMainAtom } from "recoil/explore/visibleMain.atom";
import styled from "styled-components";

const ExploreMain = () => {
  const mainVisible = useRecoilValue(visibleMainAtom);

  useEffect(() => {
    if (!mainVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [mainVisible]);

  return (
    <MainContainerStyle $mainVisible={mainVisible}>
      <MainFirstSectionStyle>
        <div>
          <Text1Style>
            You’ve <span>safely</span>
          </Text1Style>
          <Text2Style>landed in</Text2Style>
          <Text3Style>Another World</Text3Style>
        </div>
      </MainFirstSectionStyle>
      <MainSecSectionStyle>
        <SectionWrapperStyle>
          welcome! We are about to go on
          <br /> a little journey.
          <br /> Your only job is to enjoy the scenery
          <br /> unfolding in front of your eyes.
        </SectionWrapperStyle>
      </MainSecSectionStyle>
      <MainThrSectionStyle>
        <div>
          take a deep breath, <br className="pc" />
          and scroll!
        </div>
      </MainThrSectionStyle>
      <GapStyle />
    </MainContainerStyle>
  );
};

interface IStyled {
  $mainVisible: boolean;
}

const MainContainerStyle = styled.div<IStyled>`
  opacity: ${({ $mainVisible }) => ($mainVisible ? 1 : 0)};
  transition: opacity 3s linear;
  padding-top: 10px;
  background: url("../static/images/bg.webp") center top no-repeat;
  background-size: cover;
  background-color: rgba(187, 197, 227, 0.7);
`;

const MainFirstSectionStyle = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1em;
  font-size: 260px;
  text-transform: uppercase;
  text-align: center;
  span {
    color: #222;
    font-family: "DharmaGothicE";
    position: relative;
    display: block;
    margin-top: -20px;
  }

  @media (max-width: 979px) {
    font-size: 25.6vw;
    line-height: 1.1;
  }
`;

const MainSecSectionStyle = styled.section`
  height: 110vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 94px;
  line-height: 1.1;
  text-transform: uppercase;
  z-index: 11;
  div {
    color: #222;
    font-family: "DharmaGothicE";
  }
  @media (max-width: 979px) {
    font-size: 10.6666666667vw;
    justify-content: normal;
  }
`;

const MainThrSectionStyle = styled.section`
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  font-size: 240px;
  text-transform: uppercase;
  div {
    color: #222;
    font-family: "DharmaGothicE";
  }
  @media (max-width: 979px) {
    font-size: 21.3333333333vw;
    font-weight: 500;
  }
`;

const SectionWrapperStyle = styled.div`
  width: 100%;
  max-width: 1600px;
  padding-left: 80px;
  box-sizing: border-box;
  @media (max-width: 979px) {
    padding-left: 5.3333333333vw;
    width: 71.4666666667vw;
    font-size: 10.6666666667vw;
    box-sizing: content-box;
  }
`;

const Text1Style = styled.span`
  span {
    display: inline;
  }
  @media (max-width: 979px) {
    span {
      display: block;
      text-indent: -1.2em;
    }
  }
`;

const Text2Style = styled.span`
  text-indent: -3em;

  @media (max-width: 979px) {
    margin-top: 0;
    text-indent: 0;
  }
`;

const Text3Style = styled.span`
  z-index: 11;
  @media (max-width: 979px) {
    display: block;
    padding-top: 18.6666666667vw;
    line-height: 0.95;
  }
`;

const GapStyle = styled.div`
  position: relative;
  margin-top: calc(1300px * -0.2);
  margin-bottom: calc(100vh * -0.23);
  height: calc(100vh * 1.8);
  background-image: linear-gradient(
    to bottom,
    rgba(254, 235, 219, 0) 0%,
    #fbe6da 10%,
    #f4b7a6 88%,
    rgba(244, 183, 166, 0) 100%
  );
  z-index: 1;
`;

export default memo(ExploreMain);
