import { isMobile } from "react-device-detect";
import { selector } from "recoil";

export const monImagesSelector = selector({
  key: "monImagesSelector",
  get: () => {
    const monImages: string[] = [];
    for (let i = 1; i <= 100; i++) {
      const src =
        "../static/images/mon/webp/0" +
        (i < 100 ? (i < 10 ? "00" + i : "0" + i) : i) +
        ".webp";
      monImages.push(src);
    }
    return monImages;
  },
});

export const fogImagesSelector = selector({
  key: "fogImagesSelector",
  get: () => {
    const fogImages: string[] = [];
    for (let k = 1; k <= 70; k++) {
      const src =
        "../static/images/fog/1000/" + (k < 10 ? "0" + k : k) + ".webp";
      fogImages.push(src);
    }
    return fogImages;
  },
});

export const stageImagesSelector = selector({
  key: "stageImagesSelector",
  get: async () => {
    const stageImages: string[] = [];
    for (let j = 0; j <= 508; j++) {
      const src =
        "../static/images/world/stage1/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      const lowerSrc =
        "../static/images/world/stage1/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        stageImages.push(src);
      } else {
        stageImages.push(lowerSrc);
      }
    }
    return stageImages;
  },
});

export const stage2ImagesSelector = selector({
  key: "stage2ImagesSelector",
  get: () => {
    const stageImages: string[] = [];
    for (let j = 509; j <= 1016; j++) {
      const src =
        "../static/images/world/stage1/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      const lowerSrc =
        "../static/images/world/stage1/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        stageImages.push(src);
      } else {
        stageImages.push(lowerSrc);
      }
    }
    return stageImages;
  },
});
